import React from 'react';
// import config from '../../config.json';

// // Example data
// const clientFeedbackData = [
//   {
//     image: 'images/client.png',
//     name: 'Rohali Jonson',
//     role: 'Customer',
//     feedback: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined.',
//     quoteImage: 'images/quote.png',
//   },
//   {
//     image: 'images/client.png',
//     name: 'John Doe',
//     role: 'Parent',
//     feedback: 'Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for "lorem ipsum" will uncover many web sites still in their infancy.',
//     quoteImage: 'images/quote.png',
//   },
//   {
//     image: 'images/client.png',
//     name: 'Jane Smith',
//     role: 'Guardian',
//     feedback: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable.',
//     quoteImage: 'images/quote.png',
//   },
//   {
//     image: 'images/client.png',
//     name: 'Alice Johnson',
//     role: 'Supporter',
//     feedback: 'The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using "Content here, content here", making it look like readable English.',
//     quoteImage: 'images/quote.png',
//   },
//   {
//     image: 'images/client.png',
//     name: 'Bob Brown',
//     role: 'Contributor',
//     feedback: 'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the default model text for a wide range of applications.',
//     quoteImage: 'images/quote.png',
//   },
// ];



const ClientFeedback = ({parentFeedback}) => {
  return (
    <section className="client_section layout_padding">
      <div className="container layout_padding2-top">
        <div className="heading_container">
          <h2>Parent’s Feedback</h2>
          {/* <p>{config.domain}</p>
          <p>{config.api}</p>
          <p>{config.dataserver}</p> */}
        </div>
        <div id="carouselExampleControls" className="carousel slide">
          <div className="carousel-inner">
            {parentFeedback.map((feedback, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <div className="client_container layout_padding">
                  {/* <div className="img-box">
                    <img src={feedback.image} alt="Client" />
                  </div> */}
                  <div className="detail-box">
                    <h4>{feedback.guardian_name}</h4>
                    {/* <h6>{feedback.role}</h6> */}
                    <p>{feedback.review}</p>
                    {/* <img src={feedback.quoteImage} alt="Quote" /> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ClientFeedback;
