import React from 'react';
import "./Gallery.css";

const Gallery = () => {
    const images = [
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-56-07-rhymes_day_03.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-49-51-rabi1.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-56-07-rhymes_day_03.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-48-38-swim1.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-47-30-art_and_craft_06.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-55-02-annual_function_07.jpg",
        " https://heraldkids.com/backend/gallery/images/2021-03-04-13-56-07-rhymes_day_03.jpg",
    ];

    return (
        <div className='gallery-section'>
            <div className="gallery-content">
                <div className="gallery-header">
                    <h1>Gallery</h1>
                    <p>Sneak peek of the fun time!</p>
                </div>
            </div>
            <div className="festival-celebration-container">
                <div className="festival-title">
                    <p className="festival-name">Herald's World of Fun!</p>
                </div>
                <div className="festival-details">
                    <div className="celebration-events">Celebration of Festivals</div>
                    <p className="festival-description">
                        At Herald Kids, we are continually trying to keep the kids connected to India's different festivals and culture.
                        We usher in the Durga Puja by letting the little ones play the "dhak," while "Santa" comes to deliver presents
                        to the kids for Christmas. We also celebrate Rakshabandhan across the school. One of the biggest festivals we
                        celebrate is the Saraswati Puja.
                    </p>
                    <p className="saraswati-puja-celebration">
                        Every year we celebrate Saraswati Puja within our school premises in a very
                        homely atmosphere. The children and their parents take "anjali" and enjoy "bhog" after the puja.
                    </p>
                </div>
            </div>
            <div className="image-gallery">
                {images.map((image, index) => (
                    <div className="gallery-item" key={index}>
                        <img src={image} alt={`Gallery item ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Gallery;
