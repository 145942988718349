// About.js
import React from 'react';
import './AboutUs.css'; // Import CSS for styling

const AboutUs = () => {
  return (
    <div className="about-section">
      <div className="about-content">
        <div className="about-header">
          <h1>ABOUT US</h1>
          <p>Your precious are in safe hands.</p>
        </div>
      </div>
      <div className="about-body">
        <div className="about-image">
          <img src="https://heraldkids.com/images/about-body.jpg" alt="About Herald Kids"  className='image'/>
        </div>
        <div className="about-description">
          <div className="about-description-title ">Our School</div>
          <p className="about-description-text">
            "Herald Kids" is a pioneer in using unique and playful education methods with a strong emphasis
            on English as a medium of learning. The school has a professional and well-experienced team of
            teachers and a dedicated and caring team of support staff to provide the best care and attention
            to the children. Well equipped with modern apparatus, we offer a safe and stimulating
            environment for children to learn and play.
          </p>
        </div>
      </div>
      {/* <div className="about-features">
        <div className="about-feature-item">
          <img src="https://heraldkids.com/images/active-learning-icon.png" alt="Happy Environment" />
          <p>Happy Environment</p>
        </div>
        <div className="about-feature-item">
          <img src="https://heraldkids.com/images/school-icon.png" alt="Active Learning" />
          <p>Active Learning</p>
        </div>
        <div className="about-feature-item">
          <img src="https://heraldkids.com/images/creative-lesson.png" alt="Creative Mind" />
          <p>Creative Mind</p>
        </div>
        <div className="about-feature-item">
          <img src="https://heraldkids.com/images/playground.png" alt="Engaging Play Area" />
          <p>Engaging Play Area</p>
        </div>
      </div> */}
      <div className="school-section">
        <div className="school-description">
          <div className="school-title">Our School</div>
          <p className='school-descption'>
            Herald Kids was established in 2005 with the blessings and active support of Sujit Kr Saha Choudhury.
            The family hails from enriched educational backgrounds. Their exposure to the global space inspired them
            to provide early education with a strong emphasis on English to the children in Baranagar. Herald Kids
            was the first of its kind in the locality to introduce a schooling system using well-researched methods to
            develop toddlers' cognitive and motor skills between 1.5 years to 3 years of age. The growing competition
            to secure a place in the leading schools required an exceptional skill set and teaching method to prepare
            these young minds to do well in these competitive environments. They believe that the unique teaching style
            of Herald Kids has ensured success for the toddlers in finding their place in these schools. These children's
            stories and achievements in steering through the competitive academic module's challenges have been the cornerstone
            of Herald Kids' success journey. The founder's global vision has enabled them to bring a delicate balance of
            academic learning and holistic development of their personality.
          </p>
        </div>
        <div className="school-image">
          <img src="https://heraldkids.com/images/our-mission.jpg" alt="Our Mission" className='misson-image' />
        </div>
      </div>
    </div>

  );
};

export default AboutUs;
