import React from 'react';
import './HomePagePart5.css';

const HomePagePart5 = ({ schoolPrograms }) => {
  return (
    <div className="homepage-part5">
      <div className="programs-title">Our Pre-school Programs</div>
      <p className="programs-intro">
        With age-appropriate stimulation, and the right balance of learning and fun, we encourage their curiosity and fuel their creative side.
      </p>
      <div className="programsContainer">
        {schoolPrograms.map((program, index) => (
          <div className="program-box" key={index}>
            <img
              alt={program.program_name}
              src={program.program_image}
              className="programImage"
            />
            <div className="program-name">{program.program_name}</div>
            <p className="program-age">{program.program_age}</p>
            <div className="program-description">{program.program_details}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePagePart5;
