import React from 'react';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Topnavbar from './components/TopNavBar/Topnavbar';
import Header from './components/Header/Header';
import Info from './components/template/Newsletter';
import Footer from './components/template/Footer';
import AboutUs from './pages/About Us/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import Programe from './pages/Programe/Programe';
import Home from './pages/HomePage/Home';
import Gallery from './pages/Gallery/Gallery';
import './App.css';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import TermsCondition from './pages/Term_Condition/TermsCondition';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';


function App() {
  const [information, setInformation] = useState("");
  const [logo, setLogo] = useState("");
  const [data, setData] = useState(null);
  console.log(data);

  // Fetch data when the component mounts
  useEffect(() => {
    axios
      .get('https://dataserver.demoschoolmanagement.in/website/get_conf.php')
      .then((response) => {
        console.log(response.data);
        setData(response.data); 
        setInformation(response.data.principal);
        setLogo(response.data.logo);        
      })
      .catch((err) => {
        console.log(err.message);       
      });
  }, []);

  return (
    <Router>
      <Topnavbar information={information} />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/programe" element={<Programe />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/termscondition" element={<TermsCondition />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
      <Info logo={logo} />
      <Footer />
    </Router>
  );
}

export default App;



