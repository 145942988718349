import React from 'react';
import "./Home.css";
import { useEffect, useState } from 'react';
import axios from 'axios';
import CarouselComponent from '../../components/CarouselComponent/CarouselComponent';
import Offer from '../../components/template/Offer';
// import AboutSection from '../../components/template/About';
import ClientFeedback from '../../components/template/ParentsFeedback';
import ContactSection from '../../components/template/ContactForm';
import HomePagePart2 from '../../components/HomePagePart2/HomePagePart2';
import HomePagePart3 from '../../components/HomePagePart3/HomePagePart3';
import HomePagePart4 from '../../components/HomePagePart4/HomePagePart4';
import HomePagePart5 from '../../components/HomePagePart5/HomePagePart5';
import HomePagePart6 from '../../components/HomePagePart6/HomePagePart6';


const Home = () => {
  const [data, setData] = useState([]);
  const [crousalImage, setCrousalImage] = useState([]);
  const [teacherImage, setTeacherImage] = useState([]);
  const [achievements, setAchievements] = useState("");
  const [ourMoto, setOurMoto] = useState("");
  const [schoolPrograms, setSchoolPrograms] = useState([]);
  const [principalInformation, setPrincipalInformation] = useState("");
  const [notice, setNotice] = useState([]);
  const [parentFeedback,setParentFeedBack]=useState([]);

  console.log(data);

  // Fetch data when the component mounts
  useEffect(() => {
    axios
      .get('https://dataserver.demoschoolmanagement.in/website/get_conf.php')
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setCrousalImage(response.data.crowsel);
        setAchievements(response.data.achievements);
        setOurMoto(response.data.our_moto);
        setSchoolPrograms(response.data.school_programs);
        setPrincipalInformation(response.data.principal);
        setNotice(response.data.notices);
        setTeacherImage(response.data.teachers);
        setParentFeedBack(response.data.guardians_feedback)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  console.log(parentFeedback);

  return (
    <div className='homeContainer'>
      <CarouselComponent crousalImage={crousalImage} />
      <HomePagePart2 achievements={achievements} />
      <HomePagePart3 ourMoto={ourMoto} />
      <HomePagePart4 />
      <HomePagePart5 schoolPrograms={schoolPrograms} />
      <Offer notice={notice} principalInformation={principalInformation} />
      <HomePagePart6 teacherImage={teacherImage} />
      <ClientFeedback parentFeedback={parentFeedback} />
      <ContactSection />
    </div>
  );
};

export default Home;
