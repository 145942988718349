import React from 'react';
import './HomePagePart4.css'; 

const HomePagePart4 = () => {
  return (
    <div className="homepage-part4">
      <div className="facilities-title">Preschool Facilities</div>
      <div className="facilities-container">
        <div className="facility-item">
          <img
            alt="Classroom"
            src="https://cdn.fcglcdn.com/education/totsweb/live/fcintelli/classroom.svg"
            className="facility-image"
          />
          <div className="facility-label">Classroom</div>
        </div>
        <div className="facility-item">
          <img
            alt="Spacious Outdoor Play"
            src="https://cdn.fcglcdn.com/education/totsweb/live/fcintelli/outdoor-preschool.svg"
            className="facility-image"
          />
          <div className="facility-label">Spacious Outdoor Play</div>
        </div>
        <div className="facility-item">
          <img
            alt="Reading Zone"
            src="https://cdn.fcglcdn.com/education/totsweb/live/fcintelli/reading-zone.svg"
            className="facility-image"
          />
          <div className="facility-label">Reading Zone</div>
        </div>
        <div className="facility-item">
          <img
            alt="Qualified Caring Staff"
            src="https://cdn.fcglcdn.com/education/totsweb/live/fcintelli/online-session.svg"
            className="facility-image"
          />
          <div className="facility-label">Qualified Caring Staff</div>
        </div>
        <div className="facility-item">
          <img
            alt="Safe and Secure Environment"
            src="https://cdn.fcglcdn.com/education/totsweb/live/fcintelli/safe-secure-preschool.svg"
            className="facility-image"
          />
          <div className="facility-label">Safe and Secure Environment</div>
        </div>
      </div>
    </div>
  );
};

export default HomePagePart4;
