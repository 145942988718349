// CarouselComponent.js
import React, { useEffect, useRef } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./CarouselComponent.css";

// const images = [
//   {
//     src: "https://dataserver.demoschoolmanagement.in/website/crowsel_images/crowsel2.jpg",
//     alt: "First slide",
//     caption: "First Slide",
//     description: "Description for the first slide."
//   },
//   {
//     src: "https://www.plutokids.in/images/banner3.jpg?crc=4033230079",
//     alt: "Second slide",
//     caption: "Second Slide",
//     description: "Description for the second slide."
//   },
//   {
//     src: "https://www.plutokids.in/images/banner0.jpg?crc=302297366",
//     alt: "Third slide",
//     caption: "Third Slide",
//     description: "Description for the third slide."
//   },
//   {
//     src: "https://www.plutokids.in/images/banner1.jpg?crc=482738674",
//     alt: "Fourth slide",
//     caption: "Fourth Slide",
//     description: "Description for the fourth slide."
//   },
// ];

const CarouselComponent = ({crousalImage}) => {
  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      const interval = setInterval(() => {
        if (carouselRef.current) {
          carouselRef.current.next();
        }
      }, 3000); // Slide every 3 seconds

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, []);

  return (
    <Carousel ref={carouselRef} controls={false} indicators={false} wrap={true} className='carousalComponent'>
      {crousalImage.map((image, index) => (
        <Carousel.Item key={index}>
          <img
            className="carousel-image"
            src={image.crowsel_image}
            alt={image.alt}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
