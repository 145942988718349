import React from 'react';
import "./Programe.css";

const Programe = () => {
    return (
        <div className='program-overview'>
            <div className="program-intro">
                <div className="program-header">
                    <h1 className="program-title">Programs</h1>
                    <p className="program-tagline">Hop on for a fun learning experience.</p>
                </div>
            </div>
            <div className="program-container">
                <div className="program-image-wrapper">
                    <img src="https://heraldkids.com/images/playschool.jpg" alt="Play House" className='program-image' />
                </div>
                <div className="program-information">
                    <div className="program-section-title">Play House</div>
                    <p className="program-section-description">
                        The Play-house program caters to children between the ages of 1 ½ to 2 ½ years. Here, the children are
                        taught in a very friendly and activity-oriented method. The main activities involve picture reading,
                        art and craft, and storytelling. The toddlers are encouraged to express themselves through singing
                        and dancing. Children get introduced to the concept of size, shape and colour through the use of
                        different apparatus.
                    </p>
                </div>
            </div>
            <div className="program-container1">
                <div className="pre-nursery-information">
                    <div className="pre-nursery-title">Pre Nursery or Montessori</div>
                    <p className='pre-nursery-description'>
                        The Montessori program caters to children between the ages of 2 ½ years to 3 ½ years. Once the children
                        settle down in the playful environment of Herald Kids, we encourage them to share their learnings through
                        good communication skills. Here we enable them to develop self-confidence and a constructive work attitude.
                        They set their dimensions, colours, texture, and sound along with practical life skills and sensory
                        activities. The children get introduced to the concept of language and numbers along with writing skills.
                    </p>
                </div>
                <div className="pre-nursery-image-wrapper">
                    <img src="https://heraldkids.com/images/pren_n.jpg" alt="Pre Nursery or Montessori" className='pre-nursery-image' />
                </div>
            </div>
            <div className="program-container2">
                <div className="program-image-wrapper">
                    <img src="https://heraldkids.com/images/nursery.jpg" alt="About Herald Kids" className='program-image' />
                </div>
                <div className="program-information">
                    <div className="program-section-title">Nursery</div>
                    <p className="program-section-description">
                        The Nursery program caters to children between the ages of 3 ½ to 4 ½ years. We maintain a delicate balance between
                        the playful methods of learning while introducing various academic concepts. They learn to
                        develop their numeracy and literacy skills in an age-appropriate way. Learning includes
                        phonics, letters and sounds through rhymes and songs. Also, we introduce the kids to
                        learning using the computer. These help them to unfold their talent and guide them to
                        sharpen their mental and physical abilities.
                    </p>
                </div>
            </div>
            <div className="program-container3">
                <div className="pre-nursery-information">
                    <div className="pre-nursery-title">KG</div>
                    <p className='pre-nursery-description'>
                        The Kindergarten program caters to children between the ages of 4 ½ years to 5 ½ years.
                        Moving ahead with advanced academic skills, we concentrate on developing their reading
                        habits. Here the children get broader exposure to writing and mathematical concepts.
                        However, we continue to use a playful teaching method to improve their motor skills
                        by using a hands-on-activity approach. Children will have better control of English
                        conversational skills in this program. A focus on fundamental concepts on all the
                        core subjects helps them to learn difficult concepts very quickly.
                    </p>
                </div>
                <div className="pre-nursery-image-wrapper">
                    <img src="https://heraldkids.com/images/kg.jpg" alt="Our Mission" className='pre-nursery-image' />
                </div>
            </div>
        </div>
    )
}

export default Programe;
