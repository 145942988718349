import React from 'react';
import "./ContactUs.css";
import ContactSection from '../../components/template/ContactForm';

const ContactUs = () => {
    return (
        <div className='contact-container'>
            <div className="contact-us-section">
                <div className="contact-header">
                    <h1 className="contact-title">Contact Us</h1>
                    <p className="contact-message">Leave us a message and we will reply.</p>
                </div>
            </div>
            <div>
                <ContactSection />
            </div>
        </div>
    );
}

export default ContactUs;
