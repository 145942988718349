import React from 'react';
import './HomePagePart2.css';

const HomePagePart2 = ({achievements}) => {
  return (
    <div className="statistic-container">
      <div className="statistic-item">
        <div className="statistic-value">{achievements.programs_offered}</div>
        <div className="statistic-label">Program Offered</div>
      </div>
      <div className="statistic-item">
        <div className="statistic-value">{achievements.years_of_excellence}</div>
        <div className="statistic-label">Of Excellence</div>
      </div>
      <div className="statistic-item">
        <div className="statistic-value">{achievements.students_graduated}</div>
        <div className="statistic-label">Students Passed</div>
      </div>
      <div className="statistic-item">
        <div className="statistic-value">{achievements.current_teachers}</div>
        <div className="statistic-label">Teachers</div>
      </div>
    </div>
  );
};

export default HomePagePart2;
