import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1 className="privacy-policy-title">Privacy Policy</h1>
            <h2 className="privacy-policy-subtitle">Privacy Policy for Mishra Digital School</h2>
            <p className="privacy-policy-introduction">
                At Mishra Digital School, accessible from <a className="privacy-policy-link" href="https://mishradigital.net/">https://mishradigital.net/</a>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Mishra Digital School and how we use it.
            </p>
            <p className="privacy-policy-contact">
                If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us by email at <a href="mailto:Contact@mishradigital.net">Contact@mishradigital.net</a>.
            </p>

            <h3 className="privacy-policy-section-title">Log Files</h3>
            <p className="privacy-policy-content">
                Mishra Digital School follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.
            </p>

            <h3 className="privacy-policy-section-title">Cookies and Web Beacons</h3>
            <p className="privacy-policy-content">
                Like any other website, Mishra Digital School uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.
            </p>

            <h3 className="privacy-policy-section-title">Google DoubleClick DART Cookie</h3>
            <p className="privacy-policy-content">
                Google is one of the third-party vendors on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a className="privacy-policy-link" href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>.
            </p>

            <h3 className="privacy-policy-section-title">Privacy Policies</h3>
            <p className="privacy-policy-content">
                You may consult this list to find the Privacy Policy for each of the advertising partners of Mishra Digital School.
            </p>

            <h3 className="privacy-policy-section-title">Third-party Privacy Policies</h3>
            <p className="privacy-policy-content">
                Mishra Digital School’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.
            </p>

            <h3 className="privacy-policy-section-title">Children’s Information</h3>
            <p className="privacy-policy-content">
                Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
            </p>
            <p className="privacy-policy-content">
                Mishra Digital School does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
            </p>

            <h3 className="privacy-policy-section-title">Online Privacy Policy Only</h3>
            <p className="privacy-policy-content">
                This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Mishra Digital School. This policy is not applicable to any information collected offline or via channels other than this website.
            </p>

            <h3 className="privacy-policy-section-title">Consent</h3>
            <p className="privacy-policy-content">
                By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
